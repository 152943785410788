import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { CurrentUserService } from 'app/core/user/current-user.service';
import { HolidayTypeService } from './modules/main/holiday/holiday-type.service';
import { UserService } from './modules/main/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _notificationsService: NotificationsService,
        private _holidayService: HolidayTypeService,
        private _currentUserService: CurrentUserService,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return forkJoin([
            this._notificationsService.getAll(),
            this._holidayService.getAll(),
            this._currentUserService.get(),
            this._userService.getAll()
        ]);
    }
}
