import { FuseNavigationItem } from '../../../@fuse/components/navigation';

export const navigation: FuseNavigationItem[] = [
    {
        id   : 'navigation.dashboard',
        type : 'basic',
        icon : 'mat_solid:dashboard',
        link : '/dashboard'
    },
    {
        id   : 'navigation.time',
        type : 'basic',
        icon : 'mat_solid:access_time',
        link : '/time'
    },
    {
        id   : 'navigation.holiday',
        type : 'basic',
        icon : 'mat_solid:wb_sunny',
        link : '/holiday'
    },
    {
        id   : 'navigation.announcements',
        type : 'basic',
        icon : 'mat_solid:announcement',
        link : '/announcements'
    },
    {
        id   : 'navigation.wiki',
        type : 'basic',
        icon : 'mat_solid:menu_book',
        link : '/wiki'
    },
    {
        id   : 'navigation.users',
        type : 'basic',
        icon : 'mat_solid:supervised_user_circle',
        link : '/users'
    }
];
