import { SoftDeletable } from './basic.types';

export interface User extends SoftDeletable {
  id: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  background?: string;
  email: string;
  wage: number;
  status: UserStatus;
  title: string;
  roles: Role[];
  phone?: string;
  address?: string;
  birthday?: string;
  bio?: string;
}

export interface Role {
  id: string;
  name: string;
}

export enum UserStatus {
  ONLINE = 'online',
  BUSY = 'busy',
  AWAY = 'away',
  INVISIBLE = 'invisible',
  HOLIDAY = 'holiday'
}
