import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HolidayType } from '../../../shared/models/holiday.types';

@Injectable({
  providedIn: 'root'
})
export class HolidayTypeService {

  private _holidayTypes: ReplaySubject<HolidayType[]> = new ReplaySubject<HolidayType[]>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient)
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get holidayTypes$(): Observable<HolidayType[]>
  {
    return this._holidayTypes.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all available holiday types
   */
  getAll(): Observable<HolidayType[]>
  {
    return this._httpClient.get<HolidayType[]>('api/holiday-types').pipe(
        tap((availableHolidays: HolidayType[]) => {
          this._holidayTypes.next(availableHolidays);
        })
    );
  }

}

