/* eslint-disable */
import * as moment from 'moment';

export const notifications = [
    {
        id         : '6e3e97e5-effc-4fb7-b730-52a151f0b641',
        image      : 'assets/images/avatars/default.png',
        description: '<strong>Krisztian Kocsis</strong> added you to <em>Penny</em> group and assigned you as a <em>Project Manager</em>',
        time       : moment().subtract(50, 'minutes').toISOString(), // 50 minutes ago
        read       : true,
        link       : '/dashboards/project',
        useRouter  : true
    }
];
