import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { User } from '../../../shared/models/user.types';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _users: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient)
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get users$(): Observable<User[]>
  {
    return this._users.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all users
   */
  getAll(): Observable<User[]>
  {
    return this._httpClient.get<User[]>('api/users').pipe(
        tap((users: User[]) => {
          this._users.next(users);
        })
    );
  }

}
