import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'auth-callback', loadChildren: () => import('app/modules/auth/auth-callback/auth-callback.module').then(m => m.AuthCallbackModule)},
        ]
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
        ]
    },

    {
        path       : '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        resolve    : {
            initialData: InitialDataResolver,
        },
        children   : [
            {path: 'announcements', loadChildren: () => import('app/modules/main/announcement/announcement.module').then(m => m.AnnouncementModule)},
            {path: 'dashboard', loadChildren: () => import('app/modules/main/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'holiday', loadChildren: () => import('app/modules/main/holiday/holiday.module').then(m => m.HolidayModule)},
            {path: 'time', loadChildren: () => import('app/modules/main/time/time.module').then(m => m.TimeModule)},
            {path: 'wiki', loadChildren: () => import('app/modules/main/wiki/wiki.module').then(m => m.WikiModule)},
            {path: 'settings', loadChildren: () => import('app/modules/main/settings/settings.module').then(m => m.SettingsModule)},
            {path: 'users', loadChildren: () => import('app/modules/main/user/user.module').then(m => m.UserModule)},
        ]
    }
];
