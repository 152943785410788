
export const availableHolidays = [
    {
        id: '6e3e97e5-effc-4fb7-b730-52a151f0b641',
        name: 'Home office',
        max: 18
    },
    {
        id: '6e3e97e5-effc-4fb7-b730-52a151f0b642',
        name: 'Non-paid holiday',
        max: 90
    },
    {
        id: '6e3e97e5-effc-4fb7-b730-52a151f0b643',
        name: 'Paid holiday',
        max: 20
    },
    {
        id: '6e3e97e5-effc-4fb7-b730-52a151f0b644',
        name: 'School activity',
        max: -1
    },
    {
        id: '6e3e97e5-effc-4fb7-b730-52a151f0b644',
        name: 'School activity',
        max: -1
    },
    {
        id: '6e3e97e5-effc-4fb7-b730-52a151f0b644',
        name: 'Sick leave',
        max: -1
    }
];
