/* eslint-disable */
import { User, UserStatus } from '../../../shared/models/user.types';

export const users: User[] = [
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Daniel',
        lastName: 'Sebok',
        email: 'daniel.sebok@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Senior Frontend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Attila',
        lastName: 'Rickert',
        email: 'attila.rickert@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Senior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Krisztian',
        lastName: 'Kocsis',
        email: 'krisztian.kocsis@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'CTO',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Zsolt',
        lastName: 'Nyari',
        email: 'zsolt.nyari@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'CEO',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Zsolt',
        lastName: 'Nagy',
        email: 'zsolt.nagy@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Senior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Robert',
        lastName: 'Hegedus',
        email: 'robert.hegedus@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Senior Fullstack Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Krisztina',
        lastName: 'Losonc',
        email: 'krisztina.losonc@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Medior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Bence',
        lastName: 'Lakos',
        email: 'bence.lakos@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Medior Devops Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Robert',
        lastName: 'Csore',
        email: 'robert.csore@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Junior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Daniel',
        lastName: 'Martina',
        email: 'robert.csore@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Junior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Csaba',
        lastName: 'Volford',
        email: 'csaba.volford@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Medior Accountant',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Alex',
        lastName: 'Muhelyi',
        email: 'alex.muhelyi@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Junior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    },
    {
        id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
        firstName: 'Sandor',
        lastName: 'Rozsa',
        email: 'sandor.rozsa@optimaster.eu',
        avatar: 'assets/images/avatars/default.png',
        status: UserStatus.ONLINE,
        title: 'Senior Backend Engineer',
        roles: [],
        wage: 50,
        createdAt: '2021-11-05T13:18:15Z',
        modifiedAt: '2021-11-05T13:18:15Z',
        deletedAt: null
    }
];
