import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs/operators';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { navigation } from '../../../core/config/app.navigation';
import { forkJoin } from 'rxjs';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.availableLangs = this._translocoService.getAvailableLangs();

        this._translocoService.langChanges$.subscribe((activeLang) => {

            this.activeLang = activeLang;

            this._updateNavigation(activeLang);
        });

        this.flagCodes = {
            'hu': 'hu',
            'en': 'us'
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        if ( !navComponent )
        {
            return null;
        }

        navComponent.navigation = navigation;

        navigation.forEach((nav: FuseNavigationItem) => {
            const item = this._fuseNavigationService.getItem(nav.id, navigation);

            if ( item )
            {
                if (item.children && item.children.length > 0) {
                    item.children.forEach((child: FuseNavigationItem) => {
                        this._translocoService.selectTranslate(child.id).pipe(take(1))
                            .subscribe((translation) => {
                                child.title = translation
                                navComponent.refresh();
                            });
                    });
                }

                this._translocoService.selectTranslate(item.id).pipe(take(1))
                    .subscribe((translation) => {
                        item.title = translation
                        navComponent.refresh();
                    });
            }
        });


    }
}
