import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { HolidayMockApi } from './common/holiday/api';
import { UsersMockApi } from './common/users/api';

export const mockApiServices = [
    AuthMockApi,
    NotificationsMockApi,
    HolidayMockApi,
    UserMockApi,
    UsersMockApi
];
